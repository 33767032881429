@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Saira:wght@100;200&display=swap');

@font-face {
    font-family: "Vonique 43";
    src: url("vonique.otf") format("opentype");
}

/*color for timeline date must be a class*/
.whiteDate {
    color: white;
}

:root {
    --swiper-pagination-color: #3bb375;
    --swiper-navigation-color: #3bb375;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.inlineHeader {
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
    box-sizing: border-box;
    width: 100%;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1320px;
    }
}

a {
    text-decoration: none;
}

h1 {
    font-size: 48pt;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Vonique 43', serif !important;
}

@media screen and (max-width: 770px) {
    h1 {
        font-size: 30pt;
    }
}
